import React, { useEffect, useMemo, useState } from "react";
import { IRestaurant, useDBContext } from "../utils/DBContextProvider";
import { CATEGORIES, CAT_TEXT } from "./Categories";

interface IRestaurants {
  selectedCategories: Set<string>;
  selectedSubcategory: number;
  search: string;
}

const Restaurants = ({ selectedCategories, selectedSubcategory, search }: IRestaurants) => {
  const context = useDBContext();

  const restaurantsToRender = useMemo(() => {
    const discountSelected = selectedCategories.has(CATEGORIES.DISCOUNTS);
    let r =
      selectedCategories.size === 0
        ? context.restaurants
        : context.restaurants.filter(r => r.categories.some(c => selectedCategories.has(c) || (discountSelected && r.discounts.length > 0)));
    r = selectedSubcategory === 0 ? r : r.filter(r => r.subcategories.includes(selectedSubcategory));
    return search ? r.filter(r => r.name.toLowerCase().includes(search.toLowerCase())) : r;
  }, [selectedCategories, selectedSubcategory, context, search]);

  return (
    <div className="p-4 md:p-16 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 justify-center gap-5 pb-8">
      <p className="font-bold text-xl block col-span-2 md:col-span-3 lg:col-span-5">Restaurantes</p>
      {restaurantsToRender.map(r => (
        <RestaurantCard {...r} key={r.name} />
      ))}
    </div>
  );
};

const RestaurantCard = ({ name, image, description, categories, cleverlynk, discounts }: IRestaurant) => {
  const goToClynk = () => {
    // @ts-ignore
    analytics.track("Entered Restaurant", {
      name,
      cleverlynk,
    });
  };

  return (
    <a
      key={name}
      href={cleverlynk}
      className="max-w-sm rounded-2xl overflow-hidden shadow-lg cursor-pointer bg-white transition-transform duration-200 scale-100 hover:scale-105 relative"
      onClick={goToClynk}>
      <div className="absolute top-2 right-2 flex flex-col items-end gap-1">
        {discounts.map(d => (
          <div className="rounded-full bg-red-400 text-white p-1 px-2 text-xs">{d}</div>
        ))}
      </div>
      <img className="h-36 md:h-44 object-contain m-auto" src={image} alt="Foto Restaurante" />
      <div className="px-3">
        <div className="font-medium text-sm">{name}</div>
      </div>
      <div className="px-3 py-1">
        <div className="font-light text-xs text-gray-600 mb-2">{description}</div>
      </div>
      <div className="px-3 pb-1 flex flex-wrap">
        {categories.map(c => (
          <span key={c} className="inline bg-gray-200 rounded-full px-3 py-1 text-xs font-normal text-gray-700 mr-2 mb-2">
            {CAT_TEXT[c]}
          </span>
        ))}
      </div>
    </a>
  );
};

export default Restaurants;
