import React, { useEffect, useState } from "react";
import { useDBContext } from "../utils/DBContextProvider";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface INavbar {
  search: (search: string) => void;
}

const Navbar = (props: INavbar) => {
  const [search, setSearch] = useState("");
  const context = useDBContext();

  return (
    <nav
      className="h-11 md:h-14 bg-blue-900 w-full fixed top-0 left-0 z-50 flex justify-between items-center gap-6"
      style={{ backgroundColor: context.primaryColor, boxShadow: "0pt 2pt 4px -2px #00000080" }}>
      <img src={context.logo} alt="logo" className="h-8 md:h-12 w-auto pl-2"></img>
      <div className="pr-2 flex-grow max-w-sm">
        <div className="bg-white flex items-center rounded-full shadow-xl">
          <input
            className="rounded-l-full w-full py-2 px-6 text-gray-700 leading-tight focus:outline-none"
            id="search"
            type="text"
            placeholder="Search"
            onKeyDown={e => {
              if (e.key === "Enter") props.search(search);
            }}
            value={search}
            onChange={ev => {
              setSearch(ev.target.value);
              if (ev.target.value === "") props.search("");
            }}
          />
          <div className="p-1">
            <button
              className="text-white rounded-full p-1 hover:bg-blue-400 focus:outline-none w-7 h-7 md:w-8 md:h-8 flex items-center justify-center"
              style={{ backgroundColor: "#7AFFD3" }}
              onClick={() => props.search(search)}>
              <FontAwesomeIcon icon={faSearch} style={{ width: "80%", height: "80%", display: "block", margin: "auto" }} />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
