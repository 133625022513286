// @ts-nocheck
import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface ISEOProps {
  title?: string;
  description?: string;
}

function SEO(props: ISEOProps) {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        file(relativePath: { eq: "CleverlynkVertical.png" }) {
          publicURL
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{
        lang: "es",
      }}
      title={props.title || site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: props.description || site.siteMetadata.description,
        },
        {
          property: `og:title`,
          content: props.title || site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: props.description || site.siteMetadata.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          property: "og:image",
          content: file.publicURL,
        },
        {
          property: "og:image:width",
          content: 1000,
        },
        {
          property: "og:image:height",
          content: 1000,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
      ]}
    />
  );
}

export default SEO;
