import React, { useEffect, useState } from "react";
import { useDBContext } from "../utils/DBContextProvider";

interface IPresentationCard {
  name: string;
  description: string;
}

const PresentationCard = ({ name, description }: IPresentationCard) => {
  const context = useDBContext();

  return (
    <div className="block grid-rows-3 items-end pl-4 md:px-8 lg:px-16" style={{ backgroundColor: context.primaryColor, borderRadius: "0px 0px 1rem 1rem" }}>
      <div className="self-center flex-grow-0 pt-4">
        <img src={context.logo} className="h-16 rounded-xl" />
      </div>
      <div className="self-center flex-grow-0 pt-2">
        <p className="font-extrabold text-2xl lg:pb-2" style={{ color: context.textColor }}>
          {name}
        </p>
      </div>
      <div className="self-center flex-grow-0 pb-4 pr-4 lg:pb-6 text-justify whitespace-pre-wrap">
        <p style={{ color: context.textColor }}>{description}</p>
      </div>
    </div>
  );
};
export default PresentationCard;
