import React, { useContext, useEffect, useRef, useState } from "react";
import { fetchGoogleSheetsData } from "google-sheets-mapper";
import { CATEGORIES } from "../components/Categories";

export const DBContext = React.createContext<IDB | undefined>(undefined);
export interface IRestaurant {
  name: string;
  categories: CATEGORIES[];
  subcategories: number[];
  discounts: DISCOUNTS[];
  description: string;
  cleverlynk: string;
  image: string;
}

export enum DISCOUNTS {
  TWO_FOR_ONE = "2x1",
  CARNABY_CLUB = "CARNABY",
}

interface IDB {
  name: string;
  description: string;
  banner: string[];
  restaurants: IRestaurant[];
  logo: string | null;
  primaryColor: string;
  textColor: string;
  instagram: string | null;
  facebook: string | null;
  phone: string | null;
  availableCategories: CATEGORIES[];
  availableSubcategories: number[];
  loading: number;
  hasDiscount: boolean;
}

function getSheets() {
  switch (window.location.hostname) {
    case "clynk.store":
    case "www.clynk.store":
      return {
        bannerDocs: "1YuUMS3VyP3QX45gXysN1eK8_7eqXA4NKI4fMsUeQ-1o",
        infoDocs: "1BNagG__b64YeQSICmL5T9Mw35rWSDZOZi_L-XWdcRt4",
        restaurantDocs: "1pD0PDKEQAn__EihmXSJdXcQEHzdjanI2HqzT-9cU36o",
      };
    case "carnaby-street.co":
    case "www.carnaby-street.co":
      return {
        bannerDocs: "1nJbPlsqR4y4Kp9yBx_tdjlAVch0G9p_MqTk573TuD1c",
        infoDocs: "1YZhmW19C1hwrzU9oTcrcU3OZFngrynhQ02f-NeFzpgg",
        restaurantDocs: "1TYegwHq7WVnVjVQlhlR8l2BOkSxDN4Rvpjg8bte29kE",
      };
    case "muncher-juarez.co":
    case "www.muncher-juarez.co":
      return {
        bannerDocs: "1MqdSbNkaiCUKQUG-Zr-UElzNl-RclI7VwHVbJgalnRk",
        infoDocs: "1zPwb-hOHwN0RSU0xrMn6Bk4xxSXeChZkuX98N_4N_Zo",
        restaurantDocs: "1ILuHNEUKS3nGfi7NLEjCDjUvzt4eRhVvdBQOVLCe_dE",
      };
    default:
      return {
        bannerDocs: "1nJbPlsqR4y4Kp9yBx_tdjlAVch0G9p_MqTk573TuD1c",
        infoDocs: "1YZhmW19C1hwrzU9oTcrcU3OZFngrynhQ02f-NeFzpgg",
        restaurantDocs: "1TYegwHq7WVnVjVQlhlR8l2BOkSxDN4Rvpjg8bte29kE",
      };
  }
}

function DBContextProvider({ children }) {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [banner, setBanner] = useState<string[]>([]);
  const [restaurants, setRestaurants] = useState<IRestaurant[]>([]);
  const [logo, setLogo] = useState<string | null>(null);
  const [primaryColor, setPrimaryColor] = useState<string>("#000");
  const [textColor, setTextColor] = useState("#FFF");
  const [instagram, setInstagram] = useState<string | null>(null);
  const [facebook, setFacebook] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [loading, setLoading] = useState(0);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [availableCategories, setAvailableCategories] = useState<CATEGORIES[]>([]);
  const [availableSubcategories, setAvailableSubcategories] = useState<number[]>([]);
  const date = useRef(new Date());

  useEffect(() => {
    const getCategories = () => {
      const cats = new Map<CATEGORIES, number>();
      restaurants.forEach(r => r.categories.forEach(c => cats.set(c, (cats.get(c) ?? 0) + 1)));

      return Array.from(cats.entries())
        .sort((a, b) => b[1] - a[1])
        .map(c => c[0]);
    };
    setAvailableCategories(getCategories());
    setAvailableSubcategories(Array.from(new Set(restaurants.flatMap(r => r.subcategories).filter(s => s !== undefined))));
  }, [restaurants]);

  useEffect(() => {
    setHasDiscount(restaurants.some(r => r.discounts.length > 0));
  }, [restaurants]);

  async function getBanner(sheetId, apiKey) {
    fetchGoogleSheetsData({
      apiKey: apiKey,
      sheetId: sheetId,
    })
      .then(data => {
        setBanner(data[0].data.map((item: any) => item.image));
        setLoading(l => l + 1);
      })
      .catch(err => console.error("Error getting banner", err));
  }

  function validateDiscount(discounts: DISCOUNTS[] | undefined) {
    const ans = [];
    const day = date.current.getDay();

    discounts.forEach(d => {
      switch (d) {
        case DISCOUNTS.TWO_FOR_ONE:
          if (day === 2) ans.push("2x1");
          break;
        case DISCOUNTS.CARNABY_CLUB:
          if (day > 0 && day < 5) ans.push("Club Carnaby");
          break;
        default:
          ans.push(d);
      }
    });

    return ans;
  }

  async function getRestaurants(sheetId, apiKey) {
    fetchGoogleSheetsData({
      apiKey: apiKey,
      sheetId: sheetId,
    })
      .then(data => {
        setRestaurants(
          data[0].data.map((d: any) => ({
            ...d,
            categories: d.categories.split(",").map(c => c.trim()),
            subcategories: d.subcategories ? d.subcategories.split(",").map(c => Number(c.trim())) : undefined,
            discounts: validateDiscount(d.discounts ? d.discounts.split(",").map(c => c.trim()) : []),
          }))
        );
        setLoading(l => l + 1);
      })
      .catch(err => console.error("Error getting restaurants", err));
  }

  async function getInfo(sheetId, apiKey) {
    fetchGoogleSheetsData({
      apiKey: apiKey,
      sheetId: sheetId,
    })
      .then(data => {
        const d: any = data[0].data[0];
        setName(d.name);
        setDescription(d.description);
        setLogo(d.logo);
        setPrimaryColor(d.primaryColor);
        setTextColor(d.textColor);
        setInstagram(d.instagram);
        setFacebook(d.facebook);
        setPhone(d.phone);
        setLoading(l => l + 1);
      })
      .catch(err => console.error("Error getting restaurants", err));
  }

  useEffect(() => {
    console.log("Updated");
    const sheets = getSheets();
    const apiKey = "AIzaSyDNcD2J7RmVhBCIyG7QPGMHS-f4rKpc4Mw";

    getBanner(sheets.bannerDocs, apiKey);
    getRestaurants(sheets.restaurantDocs, apiKey);
    getInfo(sheets.infoDocs, apiKey);
  }, []);

  return (
    <DBContext.Provider
      value={{
        name,
        description,
        banner,
        restaurants,
        logo,
        primaryColor,
        textColor,
        instagram,
        facebook,
        phone,
        availableCategories,
        availableSubcategories,
        loading,
        hasDiscount,
      }}>
      {children}
    </DBContext.Provider>
  );
}

export const useDBContext = () => useContext(DBContext);

export default DBContextProvider;
