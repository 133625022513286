import React, { useState } from "react";
import DBContextProvider from "../utils/DBContextProvider";
import LoadingOrLoaded from "../components/LoadingOrLoaded";
import { CATEGORIES } from "../components/Categories";
import SEO from "../components/SEO";

const Index = () => {
  const [selectedSet, setSelectedSet] = useState<Set<CATEGORIES>>(new Set());
  const [selectedSubcategory, setSelectedSubcategory] = useState<number>(0);

  const toggleSelectedCategories = (category: CATEGORIES) => {
    const n = new Set(Array.from(selectedSet));
    if (selectedSet.has(category)) {
      n.delete(category);
    } else {
      n.add(category);
    }
    setSelectedSet(n);
  };

  return (
    <DBContextProvider>
      <SEO />
      <LoadingOrLoaded
        selectedSet={selectedSet}
        toggleSelectedCategories={toggleSelectedCategories}
        selectedSubcategory={selectedSubcategory}
        toggleSelectedSubcategory={n => setSelectedSubcategory(n)}
      />
    </DBContextProvider>
  );
};
export default Index;
