import React, { useEffect, useRef, useState } from "react";
import { useDBContext } from "../utils/DBContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

interface IBanner {}

const Banner = () => {
  const context = useDBContext();

  const [step, setStep] = useState<number>(0);
  const prevStepRef = useRef<number | undefined>(undefined);

  const handleClick = (mod: number) => {
    const ns = step + mod;
    if (ns >= context.banner.length) {
      setStep(0);
    } else if (ns < 0) {
      setStep(context.banner.length - 1);
    } else setStep(ns);
  };

  return (
    <div className="relative aspect-w-2 aspect-h-1 mt-11 md:mt-14">
      {context.banner.map((i, index) => (
        <Slide key={index} image={i} index={index} step={step} handleClick={handleClick} context={context} />
      ))}
    </div>
  );
};

const Slide = ({ image, step, index, handleClick, context }) => {
  return (
    <div
      className={`${step === index ? "opacity-100" : "opacity-0"} w-full bg-cover h-full absolute top-0 transition-opacity duration-300`}
      style={{
        backgroundImage: `url(${image})`,
      }}>
      <label
        onClick={() => handleClick(-1)}
        style={{ display: context.banner.length > 1 ? "block" : "none" }}
        className="control-1 w-6 md:w-10 h-6 md:h-10 ml-2 md:ml-10 absolute cursor-pointer font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto flex justify-center content-center">
        <FontAwesomeIcon icon={faAngleLeft} style={{ display: "block", margin: "auto", width: "100%", height: "100%" }} />
      </label>
      <label
        onClick={() => handleClick(1)}
        style={{ display: context.banner.length > 1 ? "block" : "none" }}
        className="control-1 w-6 md:w-10 h-6 md:h-10 mr-2 md:mr-10 absolute cursor-pointer font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto flex justify-center content-center">
        <FontAwesomeIcon icon={faAngleRight} style={{ display: "block", margin: "auto", width: "100%", height: "100%" }} />
      </label>
    </div>
  );
};
export default Banner;
