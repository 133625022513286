import React from "react";
//@ts-ignore
import CHINESE_ICON from "../images/banderitas/CHINESE.png";
//@ts-ignore
import PERUVIAN_ICON from "../images/banderitas/PERUVIAN.png";
//@ts-ignore
import COLOMBIAN_ICON from "../images/banderitas/COLOMBIAN.png";
//@ts-ignore
import ITALIAN_ICON from "../images/banderitas/ITALIAN.png";
//@ts-ignore
import USA_ICON from "../images/banderitas/USA.png";
//@ts-ignore
import MEXICAN_ICON from "../images/banderitas/MEXICAN.png";
//@ts-ignore
import JAPANESE_ICON from "../images/banderitas/JAPANESE.png";
//@ts-ignore
import FRENCH_ICON from "../images/banderitas/FRENCH.png";
//@ts-ignore
import INTERNATIONAL_ICON from "../images/banderitas/INTERNATIONAL.png";
//@ts-ignore
import BBQ_ICON from "../images/comiditas/BBQ.png";
//@ts-ignore
import SUSHI_ICON from "../images/comiditas/SUSHI.png";
//@ts-ignore
import HEALTHY_ICON from "../images/comiditas/HEALTHY.png";
//@ts-ignore
import PIZZA_ICON from "../images/comiditas/PIZZA.png";
//@ts-ignore
import HAMBURGER_ICON from "../images/comiditas/HAMBURGER.png";
//@ts-ignore
import HOTDOG_ICON from "../images/comiditas/HOTDOG.png";
//@ts-ignore
import SANDWICH_ICON from "../images/comiditas/SANDWICH.png";
//@ts-ignore
import ICECREAM_ICON from "../images/comiditas/ICECREAM.png";
//@ts-ignore
import PASTRY_ICON from "../images/comiditas/PASTRY.png";
//@ts-ignore
import BREAKFAST_ICON from "../images/comiditas/BREAKFAST.png";
//@ts-ignore
import ARAB_ICON from "../images/comiditas/ARAB.png";
//@ts-ignore
import DESSERT_ICON from "../images/comiditas/DESSERT.png";
//@ts-ignore
import VEGETARIAN_ICON from "../images/comiditas/VEGETARIAN.png";
//@ts-ignore
import VEGAN_ICON from "../images/comiditas/VEGAN.png";
//@ts-ignore
import GREEK_ICON from "../images/banderitas/GREEK.png";
//@ts-ignore
import COFFEE_ICON from "../images/comiditas/COFFEE.png";
//@ts-ignore
import ASIAN_ICON from "../images/banderitas/ASIAN.png";
//@ts-ignore
import SMOOTHIE_ICON from "../images/comiditas/SMOOTHIE.png";
//@ts-ignore
import SHOP_ICON from "../images/comiditas/SHOP.png";
//@ts-ignore
import RESTAURANT_ICON from "../images/comiditas/RESTAURANT.png";
//@ts-ignore
import BEER_ICON from "../images/comiditas/BEER.png";
//@ts-ignore
import DISCOUNTS_ICON from "../images/comiditas/DISCOUNTS.png";

import { useDBContext } from "../utils/DBContextProvider";

export enum CATEGORIES {
  ITALIAN = "ITALIAN",
  COLOMBIAN = "COLOMBIAN",
  USA = "USA",
  MEXICAN = "MEXICAN",
  JAPANESE = "JAPANESE",
  CHINESE = "CHINESE",
  PERUVIAN = "PERUVIAN",
  FRENCH = "FRENCH",
  INTERNATIONAL = "INTERNATIONAL",
  BBQ = "BBQ",
  SUSHI = "SUSHI",
  HEALTHY = "HEALTHY",
  PIZZA = "PIZZA",
  HAMBURGER = "HAMBURGER",
  HOTDOG = "HOTDOG",
  SANDWICH = "SANDWICH",
  ICECREAM = "ICECREAM",
  PASTRY = "PASTRY",
  BREAKFAST = "BREAKFAST",
  ARAB = "ARAB",
  DESSERT = "DESSERT",
  VEGETARIAN = "VEGETARIAN",
  VEGAN = "VEGAN",
  SMOOTHIE = "SMOOTHIE",
  COFFEE = "COFFEE",
  GREEK = "GREEK",
  ASIAN = "ASIAN",
  SHOP = "SHOP",
  RESTAURANT = "RESTAURANT",
  BEER = "BEER",
  DISCOUNTS = "DISCOUNTS",
}

export const CAT_ICONS = {
  ITALIAN: ITALIAN_ICON,
  COLOMBIAN: COLOMBIAN_ICON,
  USA: USA_ICON,
  MEXICAN: MEXICAN_ICON,
  JAPANESE: JAPANESE_ICON,
  CHINESE: CHINESE_ICON,
  PERUVIAN: PERUVIAN_ICON,
  FRENCH: FRENCH_ICON,
  INTERNATIONAL: INTERNATIONAL_ICON,
  BBQ: BBQ_ICON,
  SUSHI: SUSHI_ICON,
  HEALTHY: HEALTHY_ICON,
  PIZZA: PIZZA_ICON,
  HAMBURGER: HAMBURGER_ICON,
  HOTDOG: HOTDOG_ICON,
  SANDWICH: SANDWICH_ICON,
  ICECREAM: ICECREAM_ICON,
  PASTRY: PASTRY_ICON,
  BREAKFAST: BREAKFAST_ICON,
  ARAB: ARAB_ICON,
  DESSERT: DESSERT_ICON,
  VEGETARIAN: VEGETARIAN_ICON,
  VEGAN: VEGAN_ICON,
  SMOOTHIE: SMOOTHIE_ICON,
  ASIAN: ASIAN_ICON,
  GREEK: GREEK_ICON,
  COFFEE: COFFEE_ICON,
  SHOP: SHOP_ICON,
  RESTAURANT: RESTAURANT_ICON,
  BEER: BEER_ICON,
  DISCOUNTS: DISCOUNTS_ICON,
};

export const CAT_TEXT = {
  ITALIAN: "Italiano",
  COLOMBIAN: "Típico",
  USA: "Americano",
  MEXICAN: "Mexicano",
  JAPANESE: "Japonés",
  CHINESE: "China",
  PERUVIAN: "Peruana",
  FRENCH: "Francesa",
  INTERNATIONAL: "Internacional",
  BBQ: "Parrilla",
  SUSHI: "Sushi",
  HEALTHY: "Saludable",
  PIZZA: "Pizza",
  HAMBURGER: "Hamburguesa",
  HOTDOG: "Perro Caliente",
  SANDWICH: "Sánduches",
  ICECREAM: "Heladería",
  PASTRY: "Pastelería",
  BREAKFAST: "Desayuno",
  ARAB: "Árabe",
  DESSERT: "Postres",
  VEGETARIAN: "Vegetariano",
  VEGAN: "Vegano",
  SMOOTHIE: "Batidos",
  COFFEE: "Café",
  GREEK: "Griego",
  ASIAN: "Asiática",
  SHOP: "Mercado",
  RESTAURANT: "Restaurantes",
  BEER: "Cerveza",
  DISCOUNTS: "Descuentos",
};

const SUBCATEGORIES = ["Todas las fechas de entrega", "Mismo día de entrega", "Día siguiente de entrega", "Entrega programada"];

interface ICategories {
  selectedCategories: Set<CATEGORIES>;
  toggleSelectedCategories: (id: CATEGORIES) => void;
  selectedSubcategory: number;
  toggleSelectedSubcategory: (id: number) => void;
}

const Categories = ({ selectedCategories, toggleSelectedCategories, selectedSubcategory, toggleSelectedSubcategory }: ICategories) => {
  const context = useDBContext();

  function renderSubcategories(text: string, index: number) {
    return (
      <span
        key={index}
        style={{ backgroundColor: index === selectedSubcategory ? "#7AFFD3" : "#e5e7eb" }}
        onClick={() => toggleSelectedSubcategory(index)}
        className="rounded-full px-3 py-2 text-xs font-medium text-gray-900 cursor-pointer whitespace-nowrap">
        {text}
      </span>
    );
  }

  function renderCategory(c: CATEGORIES) {
    return (
      <div
        onClick={() => toggleSelectedCategories(c)}
        key={c}
        className={`h-32 md:h-40 min-h-full w-28 md:w-36 lg:w-44 grid-rows-2 items-center rounded-2xl p-1 pt-4 md:p-4 flex-shrink-0 cursor-pointer`}
        style={{
          backgroundColor: selectedCategories.has(c) ? context.primaryColor : "white",
          boxShadow: selectedCategories.has(c) ? `1pt 1pt 6px 2px ${context.primaryColor}6b` : "#0000002e 1pt 2pt 7px -1px",
        }}>
        <img src={CAT_ICONS[c]} alt={c} className="h-14 md:h-20 w-14 md:w-20 m-auto " />
        <div>
          <p
            style={{
              color: selectedCategories.has(c) ? context.textColor : "#1a1a1a",
            }}
            className="font-medium text-sm md:text-md text-center mt-2">
            {CAT_TEXT[c]}
          </p>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="pb-5 pt-2 md:pt-6">
        <p className="font-bold text-xl block md:inline pb-2 pl-4 pr-2 md:pl-16 md:pr-8 pt-3">Categorías</p>
        {context.availableSubcategories.length > 0 && (
          <div className="inline-flex flex-wrap gap-2 justify-center md:justify-start">{SUBCATEGORIES.map((text, index) => renderSubcategories(text, index))}</div>
        )}
      </div>
      <div
        className={`${
          context.availableCategories.length > 4 ? "justify-start" : "justify-center"
        } flex flex-nowrap gap-3 md:gap-5 lg:gap-8 pt-2 pl-8 px-8 md:px-16 pb-5 overflow-x-auto`}>
        {context.hasDiscount && renderCategory(CATEGORIES.DISCOUNTS)}
        {context.availableCategories.map(c => renderCategory(c))}
      </div>
      <hr className="text-gray-300 w-11/12 block m-auto" />
    </React.Fragment>
  );
};

export default Categories;
