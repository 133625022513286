import React from "react";
import { useDBContext } from "../utils/DBContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const db = useDBContext();

  return (
    <footer className="grid grid-cols-2 md:grid-cols-3 md:gap-10 gap-2 pb-4 p-8 md:py-12 relative" style={{ backgroundColor: db.primaryColor, borderRadius: "1rem 1rem 0px 0px" }}>
      <img src={db.logo} className="h-12 md:h-16 w-auto rounded-xl" alt="Logo" />
      <div className="flex gap-2 md:gap-4 items-center justify-center md:col-span-2">
        {db.instagram && (
          <a className="bg-white rounded-full w-10 h-10 p-1.5 cursor-pointer" href={db.instagram} target="_blank">
            <FontAwesomeIcon icon={faInstagram} style={{ width: "95%", height: "95%", display: "block", margin: "auto" }} />
          </a>
        )}
        {db.facebook && (
          <a className="bg-white rounded-full w-10 h-10 p-1.5 cursor-pointer" href={db.facebook} target="_blank">
            <FontAwesomeIcon icon={faFacebookF} style={{ width: "95%", height: "95%", display: "block", margin: "auto" }} />
          </a>
        )}
        {db.phone && (
          <a className="bg-white rounded-full w-10 h-10 p-1.5 cursor-pointer" href={`tel:${db.phone}`} target="_blank">
            <FontAwesomeIcon icon={faPhone} style={{ width: "90%", height: "90%", display: "block", margin: "auto" }} />
          </a>
        )}
      </div>
      <div className="col-span-3 flex justify-center mt-8">
        {/* <StaticImage src="../images/logo/CleverlynkLogo.png" alt="Cleverlynk Logo" placeholder="tracedSVG" objectFit="contain" className="cursor-pointer" width={96} /> */}
        <a href="https://www.cleverlynk.com" target="_blank" className="cursor-pointer" style={{ color: db.textColor }}>
          Powered by Cleverlynk
        </a>
      </div>
    </footer>
  );
};

export default Footer;
