import React, { useState } from "react";
import Banner from "../components/Banner";
import Categories, { CATEGORIES } from "../components/Categories";
import Navbar from "../components/Navbar";
import PresentationCard from "../components/PresentationCard";
import Restaurants from "../components/Restaurants";
import Footer from "../components/Footer";
import { useDBContext } from "../utils/DBContextProvider";
import "./../styles/loader.css";

interface ILoadingOrLoaded {
  selectedSet: Set<CATEGORIES>;
  toggleSelectedCategories: (category: CATEGORIES) => void;
  selectedSubcategory: number;
  toggleSelectedSubcategory: (id: number) => void;
}

const LoadingOrLoaded = ({ selectedSet, toggleSelectedCategories, selectedSubcategory, toggleSelectedSubcategory }: ILoadingOrLoaded) => {
  const [search, setSearch] = useState("");
  const db = useDBContext();

  if (db.loading < 3)
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white flex flex-col items-center justify-center">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      </div>
    );

  return (
    <>
      <Navbar search={s => setSearch(s)} />
      <main>
        <Banner />
        <PresentationCard description={db.description} name={db.name} />
        <Categories
          selectedCategories={selectedSet}
          toggleSelectedCategories={toggleSelectedCategories}
          selectedSubcategory={selectedSubcategory}
          toggleSelectedSubcategory={toggleSelectedSubcategory}
        />
        <Restaurants selectedCategories={selectedSet} selectedSubcategory={selectedSubcategory} search={search} />
      </main>
      <Footer />
    </>
  );
};
export default LoadingOrLoaded;
